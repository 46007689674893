import Http from "../../../utils/restClient";
import { IPostRefundData } from "./reducers/refund.reducer";

export default class PaymentsAPI {
	public static getAuthenticationKeyToken() {
		return Http.axios().get("/api/v1/payment/authenticationkey/token/create");
	}

	public static getAuthenticationKeyAuthorization(paymentAmount: number) {
		return Http.axios().post("/api/v1/payment/authenticationkey/create", {
			paymentAmount,
		});
	}

	public static getPaymentAcceptance() {
		return Http.axios().get("/api/v1/payments/acceptance");
	}

	public static postCashPay(
		orderId,
		paymentAcceptanceId,
		locationContext,
		amount,
		notes,
		stationId
	) {
		return Http.axios().post(`/api/v1/payments/${orderId}/cash/pay`, {
			paymentAcceptanceId: paymentAcceptanceId,
			locationContext: locationContext,
			amount: amount,
			notes: notes,
			stationId: stationId,
		});
	}

	public static postFSHostedCapture(
		orderId,
		transactionId,
		paymentAcceptanceId,
		locationContext,
		amount,
		notes,
		stationId
	) {
		return Http.axios().post(
			`/api/v1/payments/${orderId}/hosted/${transactionId}/capture`,
			{
				paymentAcceptanceId: paymentAcceptanceId,
				locationContext: locationContext,
				amount: amount,
				notes: notes,
				stationId: stationId,
			}
		);
	}

	public static postStripeHostedCapture(
		orderId,
		paymentAcceptanceId,
		locationContext,
		amount,
		notes,
		stationId,
		token,
		paymentModuleId
	) {
		return Http.axios().post(`/api/v1/payments/${orderId}/stripe/pay`, {
			paymentAcceptanceId: paymentAcceptanceId,
			locationContext: locationContext,
			amount: amount,
			notes: notes,
			stationId: stationId,
			token: token,
			paymentModuleId: paymentModuleId,
		});
	}

	public static postCheckPay(
		orderId,
		paymentAcceptanceId,
		locationContext,
		amount,
		nameOnCheck,
		checkNumber,
		notes,
		stationId
	) {
		return Http.axios().post(`/api/v1/payments/${orderId}/check/pay`, {
			paymentAcceptanceId: paymentAcceptanceId,
			locationContext: locationContext,
			amount: amount,
			notes: notes,
			nameOnCheck: nameOnCheck,
			checkNumber: checkNumber,
			stationId: stationId,
		});
	}

	public static postCreditPay(payload) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/credit/record-only`,
			payload
		);
	}

	public static postDebitPay(payload) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/debit/record-only`,
			payload
		);
	}

	public static postEmvPay(payload) {
		return Http.axios().post(`/api/v1/payments/${payload.orderId}/emv/pay`, payload);
	}

	public static postStripeEmvPay(payload) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/stripe/emv/pay`,
			payload
		);
	}

	public static postSwipedPay(payload) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/swiped/pay`,
			payload
		);
	}

	public static postGiftCardPay(payload) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/gift-card/pay`,
			payload
		);
	}

	public static postInterCardPay(payload) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/intercard/pay`,
			payload
		);
	}

	public static posRefund(payload: { orderId: number; data: IPostRefundData }) {
		return Http.axios().post(
			`/api/v1/payments/${payload.orderId}/refund`,
			payload.data
		);
	}

	public static GetRefundSumarry(model: any, orderId: number) {
		return Http.axios().post(`/api/v1/orders/pos/${orderId}/refund/summary`, model);
	}
}
