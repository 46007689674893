import { createSlice } from "@reduxjs/toolkit";
import { IPaymentAcceptance } from "./payments.typings";
import cashReducer, { cashState } from "./reducers/cash.reducer";
import checkReducer, { checkState } from "./reducers/check.reducer";
import processingReducer, { paymentProcessingState } from "./reducers/processing.reducer";
import hostedReducer, { hostedState } from "./reducers/hosted.reducer";
import creditPayReducer, { creditState } from "./reducers/credit.reducer";
import debitPayReducer, { debitState } from "./reducers/debit.reducer";
import emvReducer, { emvState } from "./reducers/emv.reducer";
import swipedReducer, { swipedState } from "./reducers/swiped.reducer";
import giftPayReducer, { giftPayState } from "./reducers/gift.reducer";
import refundReducer, { refundState } from "./reducers/refund.reducer";
import stripeEmvPayReducer, { stripeEmvState } from "./reducers/stripe.emv.reducer";

const initialPayments = {
	isLoadingAuthKey: false,
	authenticationKey: "",
	error: "" as string,
	isAddingToken: false,
	paymentAcceptances: {
		paymentAcceptances: [] as Array<IPaymentAcceptance>,
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
	},
	...paymentProcessingState,
	...cashState,
	...checkState,
	...hostedState,
	...creditState,
	...debitState,
	...emvState,
	...swipedState,
	...giftPayState,
	...refundState,
	...stripeEmvState,
};

const paymentsSlice = createSlice({
	name: "PAYMENTS",
	initialState: initialPayments,
	reducers: {
		...processingReducer(),
		...cashReducer(),
		...checkReducer(),
		...hostedReducer(),
		...creditPayReducer(),
		...debitPayReducer(),
		...emvReducer(),
		...swipedReducer(),
		...giftPayReducer(),
		...refundReducer(),
		...stripeEmvPayReducer(),
		getAuthenticationKeyStart: (state, { payload }) => {
			return {
				...state,
				isLoadingAuthKey: true,
				error: "",
			};
		},
		getAuthenticationKeyComplete: (state, { payload }) => {
			return {
				...state,
				isLoadingAuthKey: false,
				authenticationKey: payload.authenticationKey,
				error: "",
			};
		},
		getAuthenticationKeyFail: (state, { payload }) => {
			return {
				...state,
				isLoadingAuthKey: false,
				authenticationKey: "",
				error: payload,
			};
		},
		getPaymentAcceptanceStart: (state) => {
			return {
				...state,
				paymentAcceptances: {
					...state.paymentAcceptances,
					fetchStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		getPaymentAcceptanceComplete: (state, { payload }) => {
			return {
				...state,
				paymentAcceptances: {
					paymentAcceptances: payload.data.results.paymentAcceptances,
					fetchStateLoading: {
						...state.paymentAcceptances.fetchStateLoading,
						started: true,
						loading: false,
						success: true,
					},
				},
			};
		},
		getPaymentAcceptanceFail: (state, { payload }) => {
			return {
				...state,
				paymentAcceptances: {
					...state.paymentAcceptances,
					fetchStateLoading: {
						...state.paymentAcceptances.fetchStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	},
});

export const {
	getAuthenticationKeyStart,
	getAuthenticationKeyComplete,
	getAuthenticationKeyFail,
	getPaymentAcceptanceStart,
	getPaymentAcceptanceComplete,
	getPaymentAcceptanceFail,
	postCashPayStart,
	postCashPayComplete,
	postCashPayFail,
	resetCashPaymentState,
	triggerPaymentProcessing,
	triggerFormInvalid,
	setPaymentProcessingResult,
	resetPaymentProcessingResult,
	setPaymentProcessingApiError,
	setPaymentProcessingAmount,
	setPaymentFormInvalid,
	postCheckPayStart,
	postCheckPayComplete,
	postCheckPayFail,
	resetCheckPaymentState,
	postHostedCaptureStart,
	postHostedCaptureComplete,
	postHostedCaptureFail,
	resetHostedCaptureState,
	postCreditPayComplete,
	postCreditPayFail,
	postCreditPayStart,
	resetCreditPaymentState,
	postDebitPayComplete,
	postDebitPayFail,
	postDebitPayStart,
	resetDebitPaymentState,
	postEmvPayStart,
	postEmvPayComplete,
	postEmvPayFail,
	resetEmvPaymentState,
	postSwipedPayStart,
	postSwipedPayComplete,
	postSwipedPayFail,
	resetSwipedPaymentState,
	postInterCardPayStart,
	postGiftPayStart,
	postGiftPayComplete,
	postGiftPayFail,
	resetGiftPaymentState,
	postRefundsStart,
	postRefundsComplete,
	postRefundsFail,
	postSingleRefundComplete,
	postSingleRefundFail,
	resetRefundState,
	getRefundSummaryStart,
	getRefundSummaryComplete,
	getRefundSummaryFail,
	resetRefundSummaryItems,
	setTotalsForCustomAmmount,
	resetSuccessStateForNewRequest,
	postStripeEmvPayStart,
	postStripeEmvPayComplete,
	postStripeEmvPayFail,
	resetStripeEmvPaymentState,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
