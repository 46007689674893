import { IPaymentCompletedResponse } from "../payments.typings";

export const stripeEmvState = {
	emvPay: {
		applyStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		paymentResponse: {} as IPaymentCompletedResponse,
	},
};

const stripeEmvPayReducer = () => {
	return {
		postStripeEmvPayStart: (state, { payload }) => {
			return {
				...state,
				emvPay: {
					...state.emvPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postStripeEmvPayComplete: (state, { payload }) => {
			return {
				...state,
				emvPay: {
					...state.emvPay,
					applyStateLoading: {
						...state.emvPay.applyStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postStripeEmvPayFail: (state, { payload }) => {
			return {
				...state,
				emvPay: {
					...state.emvPay,
					applyStateLoading: {
						...state.emvPay.applyStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetStripeEmvPaymentState: (state) => {
			return {
				...state,
				emvPay: {
					applyStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					paymentResponse: {} as IPaymentCompletedResponse,
				},
			};
		},
	};
};
export default stripeEmvPayReducer;
